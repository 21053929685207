export const PerksLandingQuery = `
  query Mobile_app_perks {
    mobile_app_perks(uid: "") {
      blocks {
        ... on MobileAppPerksBlocksPerksLanding {
          __typename
          perks_landing {
            available_perks
            back_button_text
            background_color
            perks_launch {
              background_color_token
              content
              header_text
              sub_header_text
              cta_link {
                href
                title
              }
            }
            see_all_perks {
              action_url
              title
              title_color
            }
            title
            title_color
            web_title
            welcome_message
            welcome_message_color
            milestone_summary {
              current_milestone_details {
                elite_qualifying_miles
                miles_progress_message
                to_next_perk_milestone_message
                trial_heading
                trial_message
              }
              current_status_header_title
              upcoming_milestone_details {
                remaining_milestone_details
              }
              upcoming_milestone_header_title
            }
            past_perks {
              card_background_color
              field_title_color
              field_value_color
              header_title
              icon_background_border_color
              icon_background_color
              icon_color
              link_color
              perk_title_color
            }
            top_nav {
              back_icon
              back_icon_category
              back_icon_color
              info_back_icon_color
              background_color
              info_background_color
              info_title_color
              title
              title_color
            }
          }
        }
        ... on MobileAppPerksBlocksSelectPerks {
          __typename
          select_perks {
            background_color
            cta {
              background_color
              cta_icon
              cta_icon_color
              cta_icon_category
              left_icon
              left_icon_category
              left_icon_color
              left_icon_foreground_color
              multi_perks_subtitle
              left_icon_shadow_color
              multi_perks_title
              single_perk_subtitle
              single_perk_title
              subtitle_color
              title_color
            }
            title
            title_color
          }
        }
        ... on MobileAppPerksBlocksIcons {
          __typename
          icons {
            default
            default_category
            list {
              name
              category
              perk_id
            }
          }
        }
        ... on MobileAppPerksBlocksPerks {
          __typename
          perks {
            list {
              items
              past_perks_card_props {
                fields {
                  order
                  state
                  title
                  value
                }
                links {
                  href
                  order
                  redeem_instruction
                  redeem_instruction_cta_handling
                  redeem_instruction_rich_text
                  state
                  target
                  title
                  web_view_title
                }
              }
              perk_id
              perk_name
              perk_selection_tile_title
              perk_selection_tile_subtitle
              perks_summary
              confirmation {
                additional_confirmation_text
                instruction
              }
              terms_and_conditions {
                redeem_instruction_rich_text
                title
              }
            }
          }
        }
        ... on MobileAppPerksBlocksMilestones {
          __typename
          milestones {
            list {
              chip {
                subtract_color
                background_color
                text_color
                title
              }
              expanding_button {
                expanded_text
                unexpanded_text
                text_color
              }
              milestone_details
              milestonetrackermessage
              milestone_id
              milestone_heading
              name
              perks_ladder {
                showif
                text
              }
              summary
              summary_rich_text
            }
          }
        }
      }
    }
  }
`;
