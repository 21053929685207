export const PerksSubPagesQuery = `
  query Mobile_app_perks {
    mobile_app_perks(uid: "") {
      blocks {
        ... on MobileAppPerksBlocksPerksSelection {
          __typename
          perks_selection {
            background_color
            cta_button_title
            cta_disabled_background_color
            cta_disabled_color
            cta_enabled_background_color
            cta_enabled_color
            first_multiple_title
            info_content_color
            info_icon
            info_icon_category
            multiple_perks_web_title
            info_icon_color
            second_multiple_title
            single_perk_web_title
            subtitle
            subtitle_color
            title
            title_color
            confirm_selection {
              background_color
              cancel_button_background_color
              cancel_button_disabled_border_color
              cancel_button_disabled_color
              cancel_button_text_color
              cancel_button_title
              confirm_button_background_color
              confirm_button_disabled_background_color
              confirm_button_disabled_color
              confirm_button_text_color
              confirm_button_title
              icon_background_color
              icon_color
              subtitle
              subtitle_color
              title
              title_color
            }
            congratulations_selection {
              background_color
              button_background_color
              button_text
              button_text_color
              icon_background_color
              multiple_perk_color
              icon_color
              multiple_perk_text
              single_perk_color
              single_perk_text
              title
              title_color
            }
            perk {
              perk_selected {
                background_color
                icon_border_color
                border_color
                icon_background_color
                icon_color
                subtitle_color
                title_color
              }
              perk_unselected {
                background_color
                border_color
                icon_background_color
                icon_border_color
                subtitle_color
                icon_color
                title_color
              }
            }
            header {
              background_color
              cta {
                href
                title
              }
              cta_color
              multiple_subtitle
              subtitle
              subtitle_color
              title
              title_color
            }
            top {
              background_color
              left_icon
              left_icon_color
              left_icon_category
              multiple_title
              right_icon
              right_icon_category
              right_icon_color
              title
              title_color
            }
          }
        }
        ... on MobileAppPerksBlocksPerksLadder {
          __typename
          perks_ladder {
            background_color
            button_background_color
            button_text
            button_text_color
            link {
              href
              text
              text_color
            }
            title
            web_title
          }
        }
        ... on MobileAppPerksBlocksPerksRedemptionPage {
          __typename
          perks_redemption_page {
            background
            button_background_color
            button_text
            button_text_color
            list {
              nav_title
              perkid
              redemption_confirmation_page {
                back_button_cta
                confirm_button_cta
                review_field {
                  display_name
                  name
                  order
                }
                review_text
                subtitle
                title
              }
              redemptionctapage {
                date_picker_hint
                date_picker_info_bottom
                date_picker_info_top
                group {
                  error_hint
                  field_hint
                  field_instruction
                  field_name
                  validation_regex
                }
                submit_button_text
                subtitle_rich_text
                title
              }
              redemption_success_page {
                success_back_button
                success_subtitle
                success_title
              }
              redemption_failure_page {
                failure_back_button
                failure_info_rich_text
                failure_info_title
              }
            }
          }
        }
        ... on MobileAppPerksBlocksErrorPage {
          __typename
          error_page {
            back_hub_button_border_color
            back_hub_button_text_color
            back_hub_button_title
            background_color
            one_of_two_selection_title
            one_selection_title
            retry_button_background_color
            retry_button_text_color
            retry_button_title
            subtitle
            title
            two_selection_title
          }
        }
      }
    }
  }
`;
