import axios from "axios";
import { appInsights } from "./AppInsights";
import jwtDecode from "jwt-decode";
import { getSuperUserFlag } from "../helpers/superUser";
import { ASLoginUrl } from "../helpers/constants";

interface IAccountTokenServiceHandler {
  setLoading: (isLoading: boolean) => void;
  handleNotLoggedIn: () => void;
  handleSessionExpired: () => void;
  handleSuccess: (data: any) => void;
  handleError: (error: any) => void;
}

const fetchAccountToken = async (handler: IAccountTokenServiceHandler) => {

  const { setLoading, handleError } = handler;
  setLoading(true);
  try {
    const { data } = await axios
      .get(process.env.REACT_APP_ALASKA_TOKEN_URL as string, {
        withCredentials: true,
      })
      .then((response) => {
        return response;
      });
    parseTokenAndRedirect(handler, data);
  } catch (ex: any) {
    appInsights?.trackException({ exception: ex });
    handleError(new Error("Unable to retrieve your Account Information."));
  }
};

const parseTokenAndRedirect = (handler: IAccountTokenServiceHandler, result: any) => {
  const { handleNotLoggedIn, handleSessionExpired, handleSuccess, setLoading } = handler;
  const { loggedIn, token, accountGuid, alaskaLoyaltyNumber } = result;

  if (!loggedIn && (process.env.REACT_APP_AUTHENTICATION === "true" || process.env.REACT_APP_RELEASE_ENV === "test")) {
    handleNotLoggedIn();
    window.location.href = ASLoginUrl; // Redirect to login page
    return;
  }

  if (process.env.REACT_APP_RELEASE_ENV === "local") {
    console.log("Skipping token validation in local environment");
  } else {
    const decodedToken: any = jwtDecode(token);
    const nowPlus10Seconds: any = Date.now() / 1000 + 10; // +10 for network timeout
    // Redirect if token has expired.
    if (decodedToken.exp < nowPlus10Seconds) {
      handleSessionExpired();
      window.location.href = ASLoginUrl; // Redirect to login page
      return;
    }
  }

  setLoading(false);
  handleSuccess({ token, accountGuid, alaskaLoyaltyNumber, isSuperUser: getSuperUserFlag(token) });
};

export default fetchAccountToken;
