import axios, { AxiosResponse } from "axios";
import { appInsights } from "./AppInsights";
import { IGetPerksContentRequest } from "../types/perks";

const baseURL = `${process.env.REACT_APP_APIM_BASE_URL}/1/Retain/MileagePlan/mpchoice/api/MobileContent`;

const getContentService = async (
  payload: IGetPerksContentRequest,
  queryString: string = ""
): Promise<AxiosResponse<any> | null> => {
  const contentServiceURL = baseURL + queryString;
  const perksUID = process.env.REACT_APP_CONTENTSTACK_PERKS_UID;
  const subscriptionKey = process.env.REACT_APP_MPCHOICE_BFF_KEY;

  // Environment validation
  if (!baseURL || !subscriptionKey || !perksUID) {
    const errorMessage = "Required environment variables are missing";
    appInsights.trackException({ exception: new Error(errorMessage) });
    throw new Error(errorMessage);
  }

  try {
    appInsights.trackEvent({
      name: "BFF Service for pulling mobile content service perks API called",
    });

    if (payload.query?.includes(`(uid: "")`)) {
      payload.query = payload.query.replace(`(uid: "")`, `(uid: "${perksUID}")`);
    }

    const response = await axios.post<any>(
      contentServiceURL,
      { ...payload },
      {
        headers: {
          "Ocp-Apim-Subscription-Key": process.env.REACT_APP_MPCHOICE_BFF_KEY,
        },
      }
    );

    appInsights.trackEvent({
      name: "Mobile Content Service call successful",
      properties: { status: response.status },
    });

    return response;
  } catch (error: any) {
    appInsights.trackException({ exception: error });

    if (axios.isAxiosError(error)) {
      // Handle Axios-specific errors
      return error.response || null;
    } else {
      // Handle non-Axios errors
      throw new Error("An unexpected error occurred");
    }
  }
};

export default getContentService;
