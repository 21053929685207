import "./style.css";
import "./index.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import React, { Suspense, lazy } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import MileagePlanData from "./hooks/MPChoiceService";
import { mockedServer } from "./server";
import Spinner from "./components/Spinner";
import ContentProvider from "./context/ContentContext";
import OptimizelyProvider from "./context/OptimizelyContextProvider";

if (process.env.REACT_APP_RELEASE_ENV === "local") {
  console.log("Starting fake APIs");
  mockedServer();
}

const App = lazy(() => import("./App"));

export const AppRoot = () => {
  let data = MileagePlanData();
  return <RoutedAppRoot data={data} />;
};

export const RoutedAppRoot = (props: any) => {
  let mileagePlanNumber = props.data.accountState.Token.MileagePlanNumber;
  let accountGuid = props.data.accountState.Token.Guid;

  return (
    <React.StrictMode>
      <BrowserRouter>
        {accountGuid !== "" && mileagePlanNumber !== "" && (
          <OptimizelyProvider userId={accountGuid} mileagePlanNumber={mileagePlanNumber}>
            <ContentProvider>
              <Suspense fallback={<Spinner />}>
                <App data={props.data} />
              </Suspense>
            </ContentProvider>
          </OptimizelyProvider>
        )}
      </BrowserRouter>
    </React.StrictMode>
  );
};

createRoot(document.getElementById("root")!).render(<AppRoot />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
