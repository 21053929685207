export const AsBaseUrl = "https://www.alaskaair.com";
export const AsBaseUrlQa = "https://qacert.alaskaair.com";
export const MVPGoldName = "Gift a MVP Gold status";
export const ClearName = "Complimentary CLEAR® Plus membership";
export const MVPGoldFormPath = "/account/mpchoice/mvp-gold-nomination";
export const MP100KPath = "/account/mpchoice";
export const ABGFormPath = "/account/avis-status-match";
export const ABGConfirmationPath = "/account/avis-status-match/confirmation";
export const RegularTierName = "Regular";
export const MVPTierName = "MVP";
export const MVPGoldTierName = "MVP Gold";
export const MVPGold75KTierName = "MVP Gold 75K";
export const MVP100KTierName = "MVP Gold 100K";
export const ThankYouPagePath = "/account/mpchoice/confirmation";
export const ABGPreferredPlusTierName = "Preferred Plus";
export const ABGPresidentsClubTierName = "President's Club";
export const Perks = "/account/perks";
export const ASLoginUrl = "https://www.alaskaair.com/account/login";
export const ASPageNotFoundUrl = "https://www.alaskaair.com/content/page-not-found";
export const MemberBenefitChoice = {
  Lounge: 1,
  Wifi: 2,
  Miles: 3,
  Nomination: 4,
  Clear: 5,
};
export const AccountOverviewPath = "https://www.alaskaair.com/account/overview";
export const AccountMpChoicePath = "https://www.alaskaair.com/account/mpchoice";
export const MPChoiceProductName = "mp-choice";
export const AvisStatusMatchProductName = "avis-status-match";
export const AvisForgotNameLink = "https://www.avis.com/en/loyalty-profile/avis-preferred/forgot-username";
export const BasePageTitle = "Alaska Airlines";
export const AvisProductPageTitle = "Avis Car Rental Status Match - ";
export const MpChoiceProductPageTitle = "Mileage Plan 100K Benefit Choices - ";

export const PerksScreens = {
  Hub: "hub",
  PerksSelection: "perksSelection",
  PerksSelectionConfirmation: "perksSelectionConfirmation",
  PerksSelectionSuccess: "perksSelectionSuccess",
  PerksSelectionError: "perksSelectionError",
  TermsAndCondition: "termsAndCondition",
  PerksLadder: "perksLadder",
  PerksRedemptionInfo: "perksRedemptionInfo",
  PerksRedemptionSelection: "perksRedemptionSelection",
  PerksRedemptionConfirmation: "perksRedemptionConfirmation",
  PerksRedemptionError: "perksRedemptionError",
  PerksRedemptionSuccess: "perksRedemptionSuccess",
};

export const TrialPerks = {
  37: "Try MVP for a trip",
  38: "Try Gold for a trip",
  41: "Give MVP for a trip",
  42: "Give Gold for a trip",
};
export const NominationPerks = {
  43: "MVP nomination",
  45: "MVP Gold nomination",
};

export const FulfillmentPerksDict = {
  ...TrialPerks,
  ...NominationPerks,
};
export const statusTrialHowTierList: string[] = [
  "Perks status trial",
  "Perks Gift status trial",
  "Perks Nomination",
  "Tier Match - AA",
];

interface RedemptionTitleExpirationDateMap {
  [key: string]: number;
}
export const DateDisplayMap: RedemptionTitleExpirationDateMap = {
  "Redeem by": 365,
  "Gift by": 365,
  "Nominate by": 90,
};

export const statusVipHowTierList: string[] = ["Officer Nomination"];

export const irreversiblePerks = [
  "WIFI_SINGLE_FLIGHT_PASS_1",
  "WIFI_SINGLE_FLIGHT_PASS_4",
  "AVIS_UPGRADE_1",
  "AVIS_UPGRADE_2",
  "PRE_ORDER_FOOD_ITEM_1",
];