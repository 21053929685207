import jwtDecode from "jwt-decode";

export const getSuperUserFlag = (token: string): boolean => {
  if (!token) return false;

  const decodedToken: any = jwtDecode(token);
  if (decodedToken?.IsSuperUser) {
    return decodedToken.IsSuperUser.toUpperCase() === "TRUE";
  }

  return false;
};
